
import Vue from 'vue'
export const EventBus = new Vue()

const events = [] // 所有事件
export const removeAllEvents = () => {
  events.forEach((item) => {
    item?.remove?.()
  })
}
class BusEventItem {
  constructor(name) {
    this.name = name
    this.once = false
    this.tasks = []
    
    events.push(this)
  }
  /**
   * 注册EventBusItem，事件不会重复注册
   * @param {once} Boolean 是否只触发一次
   * @param {callback} Function 注册回调
   */
  subscribe({ once, callback }) {
    if (!this.name) return
    if (this.isRegister()) return

    this.once = once || false
    EventBus.$on(this.name, (...args) => callback && callback(...args))
    if (this.tasks.length) {
      if (this.once) {
        EventBus.$emit(this.name, ...this.tasks.pop())
        this.remove()
        this.tasks = []
        return
      }
      this.tasks.forEach(task => {
        EventBus.$emit(this.name, ...task)
      })
      this.tasks = []
    }
  }
  /**
   * 触发EventBusItem
   * 透传参数, once则第一次调用后remove
   */
  notify(...args) {
    if (!this.isRegister()) return
    EventBus.$emit(this.name, ...args)
    if (this.once) { this.remove() }
  }
  notifySync(...args) {
    if (this.isRegister()) {
      EventBus.$emit(this.name, ...args)
      if (this.once) { this.remove() }
    } else {
      this.tasks.push(args)
    }
  }
  remove() {
    if (!this.isRegister()) return
    EventBus.$off(this.name)
  }
  isRegister() {
    // vue2: $listeners; vue3: _events
    return !!EventBus._events[this.name]
  }
}


/** 凑单弹窗EventBus事件 **/ 
export const EventExposeAddCart = new BusEventItem('addon_expose_add_cart')
export const EventFetchError = new BusEventItem('addon_fetch_error')
export const EventViewCart = new BusEventItem('addon_view_cart')
export const EventClose = new BusEventItem('addon_close')
export const EventProgressUpdate = new BusEventItem('addon_progress_update')
export const EventSetProgressPromise = new BusEventItem('addon_set_progress_promise')

// 刷新推荐商品数据
export const EventListReset = new BusEventItem('addon_list_reset')

/** 凑单页面EventBus事件 */
export const EventPageBack = new BusEventItem('addon_back')
export const EventPageAnalysis = new BusEventItem('addon_page_analysis')

// 对外暴露的钩子
export const EventUpdateCart = new BusEventItem('addon_update_cart')
export const EventUpdateCartChildren = new BusEventItem('addon_update_cart_children')
export const EventPickItem = new BusEventItem('addon_pick_item')
export const EventUpdateBusinessCart = new BusEventItem('addon_update_business_cart')

// 利诱 EventBus 事件
export const EventUpdateIncentivePoint = new BusEventItem('addon_update_incentive_point')
export const EventUpdatedIncentivePoint = new BusEventItem('addon_updated_incentive_point')
