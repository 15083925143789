export const ITEM_CONFIGS_V3 = {
  code: 'addonListExposeCode',
  itemDAEventExposeId: '2-3-2',
  from: 'goods_list',
  sa: {
    from: 'list',
    activity_from: 'goods_list',
  },
}

// 凑单默认埋点
export const SA_INFO = {
  activity_from: '', // 来源
  state: '', // 场景值
  style: 'popup',
  poskey: 'addnewhotJson', // 用于请求abtest, 多个逗号隔开
}

// 凑单钩子函数
export const HOOKS = {
  beforeOpen: 'beforeOpen',
  close: 'close',
  viewCart: 'viewCart',
  updateCart: 'updateCart',
  pickItem: 'pickItem',
  updateBusinessCart: 'updateBusinessCart',
}

// 凑单券不可用状态
export const COUPON_DISABLE_CODES = [
  500110, 500125, 500123, 500161
]

// 凑单类型对应组件
export const ADD_ON_COMP_MAP = {
  'coupon': 'TypeCoupon',
  'returnCoupon': 'TypeReturnCoupon',
  'accessory': 'TypeAccessory',
  'freeShipping': 'TypeFreeShipping',
  'reduce': 'OtherPromotionMain', // 减价类优惠凑单弹窗 满减 typeId == 14 (包含全场和部分)
  'discount': 'OtherPromotionMain', // 折扣类优惠凑单弹窗 全场满折 typeId == 26, 25, 9
  'bundle': 'OtherPromotionMain', // x元n件 typeId == 15
}
